import { z } from 'zod';

export const notEmptyString = z
  .string({
    errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
  })
  .min(1, { message: 'Dit veld mag niet leeg zijn.' });

export const notEmptyNumber = z
  .number({
    errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
  })
  .min(0, { message: 'Dit veld mag niet leeg zijn.' })
  .max(2147483647, { message: 'Waarde te hoog.' });

export const notEmptyDate = z.date({
  errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
});

export const optionalString = z.string().optional().nullable();
export const optionalNumber = z.number().optional().nullable();

export const notEmptySlug = z
  .string({
    errorMap: () => ({ message: 'Dit veld mag niet leeg zijn.' }),
  })
  .transform((value: string) => value.replace(/[^a-zA-Z0-9-/]/g, ''));

export const isCaptcha = z
  .boolean({
    errorMap: () => ({ message: 'Valideer dat je geen robot bent.' }),
  })
  .refine((val) => !!val, 'Valideren is mislukt. Probeer het opnieuw.');
